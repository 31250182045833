var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden",
    },
    [
      !_vm.allInfoLoaded
        ? _c(
            "v-card",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "card, list-item-two-line, actions",
                  width: "350",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.validTrialGen
                ? _c(
                    "v-card",
                    { attrs: { width: "350" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "justify-center align-center nuvolos-header",
                        },
                        [
                          _c("v-img", {
                            staticClass: "py-5",
                            attrs: {
                              "max-width": "200",
                              "max-height": "22",
                              src: require("@/assets/Nuvolos-transparent.svg"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "secondary--text text-center text-h6 mt-5",
                        },
                        [_vm._v("Sign up for Nuvolos trial")]
                      ),
                      _c("v-card-text", [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("seamless-access-button", {
                              staticClass: "mb-4",
                              attrs: { redirect: _vm.$route.path + "/satosa" },
                            }),
                            _c(
                              "google-oauth-btn",
                              {
                                attrs: {
                                  redirect: _vm.$route.path + "/google",
                                },
                              },
                              [_vm._v("Sign up with Google")]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-center mt-5 mb-2" },
                              [
                                _c("v-divider"),
                                _c("span", { staticClass: "caption px-2" }, [
                                  _vm._v("OR"),
                                ]),
                                _c("v-divider"),
                              ],
                              1
                            ),
                            _c("email-signup-form", {
                              staticClass: "mt-4",
                              attrs: { loading: _vm.sendingInvitation },
                              on: { verifiedEmail: _vm.trialSignup },
                            }),
                            _c(
                              "v-dialog",
                              {
                                attrs: {
                                  value: _vm.showError,
                                  "max-width": "600",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c("v-card-title", [
                                      _vm._v("Sign-up error"),
                                    ]),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-alert",
                                          {
                                            staticClass: "mt-2",
                                            attrs: { type: "error" },
                                          },
                                          [_vm._v(_vm._s(_vm.errorContent))]
                                        ),
                                        _c("div", { staticClass: "mt-2" }, [
                                          _vm._v(
                                            " If you continue to encounter issues, "
                                          ),
                                          _c(
                                            "a",
                                            { on: { click: _vm.askForHelp } },
                                            [_vm._v("reach out to us")]
                                          ),
                                          _vm._v(" . "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "text-caption mt-6" }, [
                          _vm._v(" By continuing, you agree to Nuvolos's "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://nuvolos.cloud/legal",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Terms of Service")]
                          ),
                          _vm._v(" and "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://nuvolos.cloud/privacy-policy",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Privacy Policy")]
                          ),
                          _vm._v(" , and to receiving emails with updates. "),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    { attrs: { width: "500" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "justify-center align-center" },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { color: "white" } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { "x-large": "", color: "error" } },
                                [_vm._v("error")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "font-weight-bold text-center" },
                        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }